import React from "react"

import Layout from "../components/layout"
import {Container} from 'reactstrap'

const NotFoundPage = () => (
  <Layout title="404 Not Found">
    <Container className="py-5">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
